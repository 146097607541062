var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-bar',{on:{"filter_data":_vm.filter}}),_c('b-card',[_c('h1',[_vm._v("ทำ Search ต่อด้วย")]),_c('h1',[_vm._v("ทำ แยกสถานะ order")]),_c('h1',[_vm._v("ทำ all order")]),_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.filter_data,"rtl":_vm.direction,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm,
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'order_id')?_c('div',{staticClass:"text-nowrap"},[_c('span',[_vm._v(_vm._s(props.row.order_id))]),_vm._v(" "),_c('br'),_c('b-badge',{staticClass:"text-white",attrs:{"variant":props.row.order_status_text.variant}},[_vm._v(_vm._s(props.row.order_status_text.text))]),_c('br'),(props.row.order_status_text.lock_queue)?_c('div',[_c('b-badge',{staticClass:"text-white",attrs:{"variant":"success"}},[_vm._v("ลงคิวแล้ว")]),_c('br')],1):_vm._e(),(!props.row.order_status_text.lock_queue)?_c('div',[_c('b-badge',{staticClass:"text-white",attrs:{"variant":"danger"}},[_vm._v("ไม่ได้ลงคิว")])],1):_vm._e(),_c('b-badge',{staticClass:"text-white",attrs:{"variant":props.row.pickup_status_text.variant}},[_vm._v(_vm._s(props.row.pickup_status_text.text))]),_c('br')],1):(props.column.field === 'member')?_c('div',{staticClass:"text-nowrap"},[_c('a',{on:{"click":function () {
                _vm.$router.push('/member/view/' + props.row.member._id);
              }}},[_c('h5',{staticClass:"text-nowrap"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"12"}}),_vm._v(" "),_c('u',[_vm._v(_vm._s(props.row.member.full_name))])],1)]),_c('h5',{staticClass:"text-nowrap"},[_c('feather-icon',{attrs:{"icon":"PhoneIcon","size":"12"}}),_vm._v(" "+_vm._s(_vm._f("phone")(props.row.member.phone))+" ")],1),_c('h5',{staticClass:"text-nowrap"},[_c('feather-icon',{attrs:{"icon":"CreditCardIcon","size":"12"}}),_vm._v(" "+_vm._s(props.row.member.card_id)+" ")],1)]):(props.column.field === 'rental_info')?_c('span',[_vm._v(" รับ "+_vm._s(_vm._f("day")(props.row.start_date))+" - "),(props.row.pickup_method == _vm.self)?_c('span',[_vm._v(" "+_vm._s(_vm._f("time")(props.row.start_date))+" ")]):(props.row.pickup_method == 'messenger')?_c('span',[_vm._v(" "+_vm._s(_vm.messenger_round_text(props.row.start_date))+" ")]):_vm._e(),_c('br'),_vm._v(" คืน "+_vm._s(_vm._f("day")(props.row.end_date))+" - "+_vm._s(_vm._f("time")(props.row.end_date))+" "),_c('br'),_c('b-badge',{staticClass:"text-white",attrs:{"variant":props.row.pickup_method_text.variant}},[_vm._v(_vm._s(props.row.pickup_method_text.text))]),_c('br')],1):(props.column.field === 'booking')?_c('span',[_vm._l((props.row.booking),function(x){return _c('div',{key:x._id},[_vm._v(" - "+_vm._s(x.product_name)+" "),_c('br')])}),_c('br'),_vm._v(" จำนวน: "+_vm._s(props.row.booking.length)+" รายการ ")],2):(props.column.field === 'rental_price')?_c('span',[_vm._v(" "+_vm._s(props.row.rental_price)+"฿ ")]):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"relief-success"},on:{"click":function($event){return _vm.$router.push({
                  name: 'order-view',
                  params: { id: props.row._id },
                })}}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}}),_vm._v(" Detail ")],1)],1)]):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3', '5', '10']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }