<template>
  <div>
    <filter-bar @filter_data="filter"></filter-bar>
    <b-card>
      <h1>ทำ Search ต่อด้วย</h1>
      <h1>ทำ แยกสถานะ order</h1>
      <h1>ทำ all order</h1>

      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="filter_data"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Order -->
          <div v-if="props.column.field === 'order_id'" class="text-nowrap">
            <span>{{ props.row.order_id }}</span> <br />
            <b-badge
              :variant="props.row.order_status_text.variant"
              class="text-white"
              >{{ props.row.order_status_text.text }}</b-badge
            ><br />
            <div v-if="props.row.order_status_text.lock_queue">
              <b-badge variant="success" class="text-white">ลงคิวแล้ว</b-badge
              ><br />
            </div>
            <div v-if="!props.row.order_status_text.lock_queue">
              <b-badge variant="danger" class="text-white">ไม่ได้ลงคิว</b-badge>
            </div>
            <b-badge
              :variant="props.row.pickup_status_text.variant"
              class="text-white"
              >{{ props.row.pickup_status_text.text }}</b-badge
            ><br />
          </div>

          <!-- Column: ข้อมูลลูกค้า -->
          <div v-else-if="props.column.field === 'member'" class="text-nowrap">
            <a
              @click="
                () => {
                  $router.push('/member/view/' + props.row.member._id);
                }
              "
            >
              <h5 class="text-nowrap">
                <feather-icon icon="UserIcon" size="12" /> &nbsp;<u>{{
                  props.row.member.full_name
                }}</u>
              </h5>
            </a>
            <h5 class="text-nowrap">
              <feather-icon icon="PhoneIcon" size="12" /> &nbsp;{{
                props.row.member.phone | phone
              }}
            </h5>
            <h5 class="text-nowrap">
              <feather-icon icon="CreditCardIcon" size="12" /> &nbsp;{{
                props.row.member.card_id
              }}
            </h5>
          </div>

          <!-- Column: ข้อมูลการเช่า -->
          <span v-else-if="props.column.field === 'rental_info'">
            รับ {{ props.row.start_date | day }} -
            <span v-if="props.row.pickup_method == self">
              {{ props.row.start_date | time }}
            </span>
            <span v-else-if="props.row.pickup_method == 'messenger'">
              {{ messenger_round_text(props.row.start_date) }}
            </span>
            <br />

            คืน {{ props.row.end_date | day }} -
            {{ props.row.end_date | time }} <br />

            <b-badge
              :variant="props.row.pickup_method_text.variant"
              class="text-white"
              >{{ props.row.pickup_method_text.text }}</b-badge
            ><br />
          </span>

          <!-- Column: อุปกรณ์ที่เช่า -->
          <span v-else-if="props.column.field === 'booking'">
            <div v-for="x in props.row.booking" :key="x._id">
              - {{ x.product_name }} <br />
            </div>
            <br />
            จำนวน: {{ props.row.booking.length }} รายการ
          </span>

          <!-- Column: อุปกรณ์ที่เช่า -->
          <span v-else-if="props.column.field === 'rental_price'">
            {{ props.row.rental_price }}฿
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                variant="relief-success"
                class="btn-icon"
                @click="
                  $router.push({
                    name: 'order-view',
                    params: { id: props.row._id },
                  })
                "
              >
                <feather-icon icon="SearchIcon" /> Detail
              </b-button>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import FilterBar from "./component/FilteredBar.vue"; /// ข้อมูลการเช่า
import FilterTable from "./component/FilterTable.vue"; /// ข้อมูลการเช่า
import store from "@/store/index";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";
import "moment-timezone";

import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    FilterBar,
    FilterTable,
    VueGoodTable,
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      filter_data: [],
      pageLength: 100,
      dir: false,
      columns: [
        {
          label: "Order ID",
          field: "order_id",
        },
        {
          label: "อุปกรณ์ที่เช่า",
          field: "booking",
        },
        {
          label: "ข้อมูลลูกค้า",
          field: "member",
        },
        {
          label: "ข้อมูลการเช่า",
          field: "rental_info",
        },

        {
          label: "ค่าเช่า",
          field: "rental_price",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  methods: {
    filter(x) {
      this.filter_data = x.filter_data;
    },
    messenger_round_text(x) {
      const time = moment(x).format("HH:mm");
      if (time == "11:30") {
        return "รอบที่ 1 | 13:00-16:00";
      } else if (time == "14:00") {
        return "รอบที่ 2 | 16:00-18:00";
      }
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  filters: {
    phone: function (phone) {
      return phone.replace(/(\d{3})(\d{3})(\d+)/, "$1-$2-$3");
    },
    day: function (date) {
      return moment(date).format("D/M");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.badge {
  margin-top: 0.5rem;
}
</style>
